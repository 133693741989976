.App {
  text-align: left;
  background-color: #e4e4e4;
}

.split {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
}

.top {
  align-items: flex-start;
  padding: 15px;
}

.row {
  display: flex;
  align-items: center;
  gap: 15px;
}

.section {
  padding: 15px 10%;
}

.section1 {
  background-color: #431d2a;
  color: white;
}

.section1 a {
  color: white;
}

.section3 {
  color: #5f172e;
}

.section4 {
  background-color: #ffffff;
  color: black;
}
.section4 a {
  color: #ff0000;
}
.noUnderline a {
  text-decoration: none;
}

.section2 h1 {
  color: #5f172e;
}

.footer {
  display: flex;
  flex-direction: column;
}

.button {
  width: min-content;
  padding: 10px 15px;
  color: #5f172e;
  margin-bottom: 30px;
  margin-top: 10px;
}

.header {
  background-image: url("./img/studio.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.header h1 {
  font-size: 5em;
  padding: 5vh 10vw;
  border: 6px solid white;
  text-align: center;
}


span,
p {
  font-size: 16pt;
  line-height: 1.7999999999999998;
}

span {
  font-weight: 600;
}

.lists h4 {
  margin-bottom: 0;
}

.lists p {
  margin-top: 5px;
}

.titleBar {
  position: sticky;
  top: 0;
  background-color: #1b1b1b;
}

.logoHeader {
  width: 40%;
  max-width: 300px;
}
.logoHeaderSmall {
  width: 35px;
  padding: 10px 25px;
}

iframe {
  border: none;
  outline: none;
  width: 100%;
  height: 800px;
}

@media (max-width: 800px) {
  .split {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}